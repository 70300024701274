import {
  IndicatorOutput,
  Questionnaire,
  User,
  UserResultReference,
} from "@/types/objects";
import { State } from "./state";

export default {
  ///////////////////
  // Questionnaire //
  ///////////////////
  addQuestionnaire(state: State, questionnaire: Questionnaire) {
    const lQuestionnaire: Questionnaire = new Questionnaire({
      uuid: questionnaire.uuid,
      id: questionnaire.id,
      surveyModel: questionnaire.surveyModel,
      indicatorCalculation: questionnaire.indicatorCalculation,
      indicatorTranslator: questionnaire.indicatorTranslator,
    });
    state.questionnaires.push(lQuestionnaire);
  },
  addQuestionnaires(state: State, questionnaires: Questionnaire[]) {
    questionnaires.forEach((questionnaire) => {
      const lQuestionnaire: Questionnaire = new Questionnaire({
        uuid: questionnaire.uuid,
        id: questionnaire.id,
        surveyModel: questionnaire.surveyModel,
        indicatorCalculation: questionnaire.indicatorCalculation,
        indicatorTranslator: questionnaire.indicatorTranslator,
      });
      state.questionnaires.push(lQuestionnaire);
    });
  },
  removeQuestionnaire(state: State, questionnaireUuid: string) {
    return state.questionnaires.splice(
      state.questionnaires.findIndex(
        (questionnaire) => questionnaire.uuid == questionnaireUuid
      ),
      1
    );
  },
  removeAllQuestionnaires(state: State) {
    state.questionnaires = [];
  },
  setActiveQuestionnaire(state: State, questionnaire: Questionnaire) {
    // if (state.questionnaires.includes(questionnaire)) {
    //   state.activeQuestionnaire = questionnaire;
    // }
    state.activeQuestionnaire = questionnaire;
  },
  removeActiveQuestionnaire(state: State) {
    state.activeQuestionnaire = undefined;
  },

  /////////////
  // Results //
  /////////////
  addResults(state: State, results: UserResultReference[]) {
    results.forEach((result: UserResultReference) => {
      const lUserResultReference: UserResultReference = new UserResultReference(
        {
          uuid: result.uuid,
          questionnaireTitle: result.questionnaireTitle,
          resultGroupUuid: result.resultGroupUuid,
          userTitle: result.userTitle,
          email: result.email,
          isInterim: result.isInterim,
          creationDate: result.creationDate,
        }
      );
      state.results.push(lUserResultReference);
    });
  },
  removeResult(state: State, resultUuid: string) {
    return state.results.splice(
      state.results.findIndex((result) => result.uuid == resultUuid),
      1
    );
  },
  removeAllResults(state: State) {
    state.results = [];
  },
  updateActiveResultUUID(state: State, uuid: string) {
    const lIndicatorOutput: IndicatorOutput = new IndicatorOutput({
      uuid: uuid,
      questionnaireTitle: { default: "", en: "", fr: "" },
      userTitle: "",
      resultGroupUuid: "",
      indicatorValues: [],
      gettingStarted: [],
      recommendations: [],
      pillars: [],
      isInterim: false,
    });
    state.activeResults = lIndicatorOutput;
  },
  addActiveResults(state: State, indicatorOutput: IndicatorOutput) {
    const lIndicatorOutput: IndicatorOutput = new IndicatorOutput({
      uuid: indicatorOutput.uuid,
      questionnaireTitle: indicatorOutput.questionnaireTitle,
      userTitle: indicatorOutput.userTitle,
      resultGroupUuid: indicatorOutput.resultGroupUuid,
      indicatorValues: indicatorOutput.indicatorValues,
      gettingStarted: indicatorOutput.gettingStarted,
      recommendations: indicatorOutput.recommendations,
      pillars: indicatorOutput.pillars,
      isInterim: indicatorOutput.isInterim,
    });
    state.activeResults = lIndicatorOutput;
  },
  removeActiveResults(state: State) {
    state.activeResults = undefined;
  },
  addActiveResultAnswers(state: State, answers: Record<string, unknown>) {
    state.activeResultAnswers = answers;
  },
  removeResultAnswers(state: State) {
    state.activeResultAnswers = undefined;
  },

  ///////////
  // Users //
  ///////////
  addUsers(state: State, users: User[]) {
    users.forEach((user: User) => {
      const lUser: User = new User({
        username: user.username,
        email: user.email,
        isAdmin: user.isAdmin,
        isExpert: user.isExpert,
      });
      state.users.push(lUser);
    });
  },

  removeUsers(state: State) {
    state.users = [];
  },
};
