import { Questionnaire, User } from "@/types/objects";
import { ActionContext } from "vuex";
import axios from "axios";

import { State } from "./state";

export default {
  ///////////////////
  // Questionnaire //
  ///////////////////
  async loadQuestionnaireFromApi(
    context: ActionContext<State, State>,
    questionnaireUuid: string
  ) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_ADDR}/questionnaires/${questionnaireUuid}`
      );
      context.commit("addQuestionnaire", response.data);
    } catch (e) {
      console.dir(e);
      return false;
    }
  },
  async loadAllQuestionnairesFromApi(context: ActionContext<State, State>) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_ADDR}/questionnaires`
      );
      context.commit("addQuestionnaires", response.data);
    } catch (e) {
      console.dir(e);
      return false;
    }
  },
  async removeQuestionnaireFromApi(
    context: ActionContext<State, State>,
    questionnaireUuid: string
  ) {
    try {
      await axios.delete(
        `${process.env.VUE_APP_API_ADDR}/questionnaires/${questionnaireUuid}`
      );
      context.commit("removeQuestionnaire", questionnaireUuid);
    } catch (e) {
      console.dir(e);
      return false;
    }
  },
  async saveNewQuestionnaireToApi(
    context: ActionContext<State, State>,
    lQuestionnaire: Questionnaire
  ) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_ADDR}/questionnaires`,
        lQuestionnaire
      );
      context.commit("addQuestionnaires", response.data);
    } catch (e) {
      console.dir(e);
      return false;
    }
  },
  addQuestionnaire(
    context: ActionContext<State, State>,
    questionnaire: Questionnaire
  ) {
    context.commit("addQuestionnaire", questionnaire);
  },
  addQuestionnaires(
    context: ActionContext<State, State>,
    questionnaires: Questionnaire[]
  ) {
    context.commit("addQuestionnaires", questionnaires);
  },
  removeQuestionnaire(
    context: ActionContext<State, State>,
    questionnaireId: string
  ) {
    context.commit("removeQuestionnaire", questionnaireId);
  },
  removeAllQuestionnaires(context: ActionContext<State, State>) {
    context.commit("removeAllQuestionnaires");
  },
  setActiveQuestionnaire(
    context: ActionContext<State, State>,
    questionnaire: Questionnaire
  ) {
    context.commit("setActiveQuestionnaire", questionnaire);
  },
  removeActiveQuestionnaire(context: ActionContext<State, State>) {
    context.commit("removeActiveQuestionnaire");
  },

  /////////////
  // Results //
  /////////////
  async saveResultsToApi(
    context: ActionContext<State, State>,
    resultsObj: {
      results: JSON;
      username: string;
      email: string;
      resultGroupUuid?: string;
    }
  ) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_ADDR}/results`,
        {
          questionnaire: context.getters.activeQuestionnaire,
          surveyResults: resultsObj.results,
          resultGroupUuid: resultsObj.resultGroupUuid,
          username: resultsObj.username,
          email: resultsObj.email,
        }
      );
      context.commit("updateActiveResultUUID", response.data);
    } catch (e) {
      console.dir(e);
      return false;
    }
  },
  async loadResultsFromApi(
    context: ActionContext<State, State>,
    resultsUuid: string
  ) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_ADDR}/results/${resultsUuid}`
      );
      context.commit("addActiveResults", response.data);
    } catch (e) {
      console.dir(e);
      return e;
    }
  },
  async loadAllUserResults(
    context: ActionContext<State, State>,
    username: string
  ) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_ADDR}/results/user/${username}`
      );
      context.commit("addResults", response.data);
    } catch (e) {
      console.dir(e);
      return e;
    }
  },
  async loadAllResultReferences(context: ActionContext<State, State>) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_ADDR}/results/references`
      );
      context.commit("addResults", response.data);
    } catch (e) {
      console.dir(e);
      return e;
    }
  },
  removeResult(context: ActionContext<State, State>, resultUuid: string) {
    context.commit("removeResult", resultUuid);
  },
  removeAllResults(context: ActionContext<State, State>) {
    context.commit("removeAllResults");
  },
  updateActiveResultUUID(
    context: ActionContext<State, State>,
    resultUuid: string
  ) {
    context.commit("updateActiveResultUUID", resultUuid);
  },
  async loadResultAnswers(
    context: ActionContext<State, State>,
    resultsUuid: string
  ) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_ADDR}/results/answers/${resultsUuid}`
      );
      context.commit("addActiveResultAnswers", response.data);
    } catch (e) {
      console.dir(e);
      return e;
    }
  },
  removeResultAnswer(context: ActionContext<State, State>) {
    context.commit("removeResultAnswers");
  },
  async loadQuestionnaireFromResult(
    context: ActionContext<State, State>,
    resultsUuid: string
  ) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_ADDR}/results/questionnaire/${resultsUuid}`
      );
      context.commit("setActiveQuestionnaire", response.data);
    } catch (e) {
      console.dir(e);
      return e;
    }
  },

  ///////////
  // Users //
  ///////////

  async createNewUser(
    context: ActionContext<State, State>,
    user: User
  ): Promise<void | { error: string } | boolean> {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_ADDR}/users`,
        {
          email: user.email,
          isAdmin: user.isAdmin,
          isExpert: user.isExpert,
        }
      );
      // console.log(response);
      if (response.data.email == "UsernameExistsException") {
        return { error: "UsernameExistsException" };
      }
    } catch (e) {
      console.dir(e);
      return false;
    }
  },

  async updateUser(
    context: ActionContext<State, State>,
    user: User
  ): Promise<void | { error: string } | boolean> {
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_API_ADDR}/users/${user.username}`,
        {
          username: user.username,
          email: user.email,
          isAdmin: user.isAdmin,
          isExpert: user.isExpert,
        }
      );
      // console.log(response);
      if (response.status == 404) {
        return { error: response.statusText };
      }
    } catch (e) {
      console.dir(e);
      return false;
    }
  },

  async deleteUser(
    context: ActionContext<State, State>,
    username: string
  ): Promise<void | { error: string } | boolean> {
    try {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_ADDR}/users/${username}`
      );
      // console.log(response);
      if (response.status == 404) {
        return { error: response.statusText };
      }
    } catch (e) {
      console.dir(e);
      return false;
    }
  },

  async loadAllUsers(context: ActionContext<State, State>) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_ADDR}/users`);
      context.commit("addUsers", response.data);
    } catch (e) {
      console.dir(e);
      return e;
    }
  },

  removeUsers(context: ActionContext<State, State>) {
    context.commit("removeUsers");
  },
};
