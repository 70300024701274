import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 2,
  class: "space-y-5 flex flex-col"
}
const _hoisted_3 = { class: "flex flex-col space-y-5" }
const _hoisted_4 = {
  key: 0,
  class: "text-red-400 text-sm"
}
const _hoisted_5 = { class: "flex flex-col space-y-5" }
const _hoisted_6 = { class: "flex flex-row justify-between" }
const _hoisted_7 = {
  id: "search-bar",
  class: "flex flex-row items-center space-x-4"
}
const _hoisted_8 = {
  id: "admin-toggle",
  class: "flex flex-row justify-center text-left space-x-3 shrink-0 text-lg"
}
const _hoisted_9 = { class: "flex flex-col space-y-3 my-4 w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_load_spinner = _resolveComponent("v-load-spinner")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_v_heading = _resolveComponent("v-heading")!
  const _component_v_input = _resolveComponent("v-input")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_modal = _resolveComponent("v-modal")!
  const _component_VHeading = _resolveComponent("VHeading")!
  const _component_v_user_button = _resolveComponent("v-user-button")!
  const _component_v_section = _resolveComponent("v-section")!

  return (_openBlock(), _createBlock(_component_v_section, null, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_v_load_spinner, {
            key: 0,
            text: _ctx.loadingText
          }, null, 8, ["text"]))
        : (_ctx.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createTextVNode(_toDisplayString(_ctx.t("loadingUsersFailed")) + " ", 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.error), 1),
              _createVNode(_component_v_button, { onClick: _ctx.getAllUsers }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("tryAgain")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_v_modal, {
                id: "new_user_modal",
                "modal-active": _ctx.newUserModalOpen,
                "show-save-button": true,
                onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setNewUserModalOpen(false))),
                onSaveData: _ctx.handleNewUserCreation
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_v_heading, { rank: "h3" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("createNewUser")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_input, {
                      id: "newUserEmail",
                      "aria-label": "new user email",
                      class: "grow",
                      type: "text",
                      placeholder: _ctx.t('email'),
                      modelValue: _ctx.newUser.email,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newUser.email) = $event))
                    }, null, 8, ["placeholder", "modelValue"]),
                    _createVNode(_component_v_input, {
                      id: "newUserConfirmEmail",
                      "aria-label": "new user email",
                      class: "grow",
                      type: "text",
                      placeholder: _ctx.t('confirmEmail'),
                      modelValue: _ctx.newUserEmailConfirm,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newUserEmailConfirm) = $event))
                    }, null, 8, ["placeholder", "modelValue"]),
                    (!_ctx.emailFieldsMatch)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.t("emailNotMatchingWarning")), 1))
                      : _createCommentVNode("", true),
                    (_ctx.newUserModalOpen)
                      ? (_openBlock(), _createBlock(_component_v_switch, {
                          key: 1,
                          id: "newUserAdminToggle",
                          name: "newUserAdminToggle",
                          modelValue: _ctx.newUser.isAdmin,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newUser.isAdmin) = $event)),
                          label: _ctx.t('makeAdmin')
                        }, null, 8, ["modelValue", "label"]))
                      : _createCommentVNode("", true),
                    (_ctx.newUserModalOpen)
                      ? (_openBlock(), _createBlock(_component_v_switch, {
                          key: 2,
                          id: "newUserExpertToggle",
                          name: "newUserExpertToggle",
                          modelValue: _ctx.newUser.isExpert,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newUser.isExpert) = $event)),
                          label: _ctx.t('makeExpert')
                        }, null, 8, ["modelValue", "label"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["modal-active", "onSaveData"]),
              _createVNode(_component_v_modal, {
                id: "update_user_modal",
                "modal-active": _ctx.updateUserModalOpen,
                "show-save-button": true,
                onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setUpdateUserModalOpen(false))),
                onSaveData: _ctx.handleUpdateUser
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_v_heading, { rank: "h3" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("updateUser")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_input, {
                      id: "updateUserEmail",
                      "aria-label": "update user email",
                      class: "grow",
                      type: "text",
                      placeholder: _ctx.t('email'),
                      modelValue: _ctx.currUser.email,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.currUser.email) = $event))
                    }, null, 8, ["placeholder", "modelValue"]),
                    (_ctx.updateUserModalOpen)
                      ? (_openBlock(), _createBlock(_component_v_switch, {
                          key: 0,
                          id: "updateUserAdminToggle",
                          name: "updateUserAdminToggle",
                          modelValue: _ctx.currUser.isAdmin,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.currUser.isAdmin) = $event)),
                          label: _ctx.t('makeAdmin')
                        }, null, 8, ["modelValue", "label"]))
                      : _createCommentVNode("", true),
                    (_ctx.updateUserModalOpen)
                      ? (_openBlock(), _createBlock(_component_v_switch, {
                          key: 1,
                          id: "updateUserExpertToggle",
                          name: "updateUserExpertToggle",
                          modelValue: _ctx.currUser.isExpert,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.currUser.isExpert) = $event)),
                          label: _ctx.t('makeExpert')
                        }, null, 8, ["modelValue", "label"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_button, { onClick: _ctx.handleDeleteUser }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("deleteUser")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ]),
                _: 1
              }, 8, ["modal-active", "onSaveData"]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_VHeading, { rank: "h1" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("users")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_button, {
                  class: "mt-auto mb-auto",
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.setNewUserModalOpen(true)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("newUser")), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_v_input, {
                  id: "searchBar",
                  "aria-label": "Search Users",
                  class: "grow",
                  type: "text",
                  name: "resultsSearch",
                  placeholder: _ctx.t('searchUsers'),
                  modelValue: _ctx.searchStr,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.searchStr) = $event))
                }, null, 8, ["placeholder", "modelValue"]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_v_switch, {
                    id: "adminToggle",
                    name: "adminToggle",
                    modelValue: _ctx.isAdminFilter,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.isAdminFilter) = $event)),
                    label: _ctx.t('showOnlyAdmin')
                  }, null, 8, ["modelValue", "label"])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredUsers, (user) => {
                  return (_openBlock(), _createBlock(_component_v_user_button, {
                    key: user.username,
                    user: user,
                    intractable: true,
                    onClick: ($event: any) => (_ctx.setUpdateUserModalOpen(true, user))
                  }, null, 8, ["user", "onClick"]))
                }), 128))
              ])
            ]))
    ]),
    _: 1
  }))
}