import { createI18n } from "vue-i18n";

export const messages = {
  en: {
    appTitle: "Questionnaire",
    forCloud: "For Cloud",
    languageSelector: "Select language",
    signIn: "Sign In",
    signOut: "Sign Out",
    contactUs: "Contact Us",
    welcome: "Welcome",
    home: "Home",
    about: "About",
    company: "Company",
    resources: "Resources",
    loadingQuestionnaires: "Loading questionnaires...",
    loadingQuestionnairesFailed: "Failed to load questionnaires :(",
    tryAgain: "Try again",
    homeGreeting: "Welcome to the Questionnaire",
    noQuestionnaire: "No questionnaire selected",
    results: "Results",
    interimResults: "Interim Results",
    loadingResults: "Loading results...",
    loadingResultsFailed: "Failed to load results :(",
    indicator: "Indicator",
    status: "Status",
    notes: "Notes",
    recommendations: "Overall Findings and Recommendations",
    gettingStarted: "Getting Started / Useful Links",
    supportingInfo: "Supporting Information",
    guidance: "Guidance: Cloud Centre of Expertise",
    shareReport: "Share Report",
    savePDF: "Save as PDF",
    noRecommendation: "No recommendations",
    noGettingStarted: "No suggestions",
    noSupportingInfo: "no supporting information",
    noGuidance: "No guidance available at this time",
    prevResults: "Previous Results",
    loadingUserResults: "Loading your previous results...",
    loadingUserResultsFailed: "Failed to load previous results :(",
    userResultsTitle: "Previous Results",
    interimDetails:
      "Your answers need to be reviewed by one of our subject matter experts for confirmation. The QuesTool team will be in touch by email shortly when final results are available.",
    allResults: "All Results",
    searchResults: "Search Results",
    searchUsers: "Search Users",
    showOnlyInterim: "Show only Interim results",
    seeAnswers: "See Answers",
    seeResults: "See Results",
    users: "Users",
    showOnlyAdmin: "Show only admin users",
    admin: "Administrator",
    expert: "SME",
    email: "Email",
    confirmEmail: "Confirm email",
    makeAdmin: "Make user an administrator",
    makeExpert: "Make user a SME",
    usernameExistsException: "Email already registered as a user",
    userCreatedSuccessfully: "New user created successfully",
    userUpdatedSuccessfully: "User updated successfully",
    emailNotMatchingWarning: "Email fields must match",
    loadingUsers: "Loading users...",
    loadingUsersFailed: "Failed to load users :(",
    helpDesk: "Help Desk",
    naviGov: "NaviGov",
    naviBlog: "NaviBlog",
    deleteUser: "Delete user",
    userDeletedSuccessfully: "User deleted successfully",
    createNewUser: "Create new user",
    newUser: "New user",
    updateUser: "Update user",
    finalResultDetails:
      "Your answers have been reviewed by one of our subject matter experts. Final results are available",
    thisIsFinalResult: "These are the final results",
    here: "here",
    reRunQuestionnaire: "Re-Run Questionnaire",
    accessDenied: "Access denied",
    markAsFinal: "Mark as final Result",
  },
  fr: {
    appTitle: "Questionnaire",
    forCloud: "Pour l'infonuagique",
    languageSelector: "Choisir la langue",
    signIn: "Connexion",
    signOut: "Se déconnecter",
    contactUs: "Nous contacter",
    welcome: "Bienvenue",
    home: "Page d'accueil",
    about: "À propos de nous",
    company: "Entreprise",
    resources: "Ressources",
    loadingQuestionnaires: "Chargement de questionnaires en cours...",
    loadingQuestionnairesFailed: "Échec du chargement des questionnaires :(",
    tryAgain: "Essayez encore",
    homeGreeting: "Bienvenue au questionnaire",
    noQuestionnaire: "Aucun questionnaire sélectionné",
    results: "Résultats",
    interimResults: "Résultats intermédiaires",
    loadingResults: "Chargement des résultats en cours...",
    loadingResultsFailed: "Échec du cahrgement des résultats :(",
    indicator: "Indicateur",
    status: "Statut",
    notes: "Notes",
    recommendations: "Conclusions et recommandations générales",
    gettingStarted: "Pour commencer / Liens utiles",
    supportingInfo: "Informations complémentaires",
    guidance: "Orientation : Centre d'expertise du nuage",
    shareReport: "partager rapport",
    savePDF: "Enregistrer en PDF",
    noRecommendation: "Pas de recommandations",
    noGettingStarted: "Pas de suggestions",
    noSupportingInfo: "Pas d'informations complémentaires",
    noGuidance: "Aucune orientation disponible pour le moment",
    prevResults: "Résultats précédents",
    loadingUserResults: "Chargement de vos résultats précédents...",
    loadingUserResultsFailed: "Échec du chargement des résultats précédents :(",
    userResultsTitle: "Résultats précédents",
    interimDetails:
      "Vos réponses doivent être revus par l'un de nos experts en la matière pour confirmation. L'équipe QuesTool vous contactera prochainement par email lorsque les résultats finaux seront disponibles.",
    allResults: "Tous les résultats",
    searchResults: "Recherche résultats",
    searchUsers: "Recherche utilisateurs",
    showOnlyInterim: "Afficher uniquement les résultats provisoires",
    seeAnswers: "Voir les réponses",
    seeResults: "Voir les Résultats",
    users: "Utilisateurs",
    showOnlyAdmin: "Afficher uniquement les utilisateurs administrateurs",
    admin: "Administrateur",
    expert: "SME",
    email: "Courriel",
    confirmEmail: "confirmer le courriel",
    makeAdmin: "Faire de l'utilisateur l'administrateur",
    makeExpert: "Faire de l'utilisateur un SME",
    usernameExistsException: "Email déjà enregistré en tant qu'utilisateur",
    userCreatedSuccessfully: "Création réussie d'un nouvel utilisateur",
    userUpdatedSuccessfully: "L'utilisateur a été mis à jour avec succès",
    emailNotMatchingWarning: "Les champs de courriel doivent correspondre",
    loadingUsers: "Chargement d'utilisateurs précédents...",
    loadingUsersFailed: "Échec du chargement des utilisateurs précédents :(",
    helpDesk: "Service d'assistance",
    naviGov: "NaviGov",
    naviBlog: "NaviBlog",
    deleteUser: "Supprimer l'utilisateur",
    userDeletedSuccessfully: "Utilisateur supprimé avec succès",
    createNewUser: "Créer un nouvel utilisateur",
    newUser: "Nouvel utilisateur",
    updateUser: "Modifier l'utilisateur",
    finalResultDetails:
      "Vos réponses ont été examinées par l'un de nos experts en la matière. Les résultats finaux sont disponibles",
    thisIsFinalResult: "Voici les résultats finaux",
    here: "ici",
    reRunQuestionnaire: "Refais le questionnaire",
    accessDenied: "Accès refusé",
    markAsFinal: "Marquer comme résultat final",
  },
};

export const languages = Object.getOwnPropertyNames(messages);

function checkDefaultLanguage() {
  let matched = null;
  languages.forEach((lang) => {
    if (lang === navigator.language) {
      matched = lang;
    }
  });
  if (!matched) {
    languages.forEach((lang) => {
      const languagePartials = navigator.language.split("-")[0];
      if (lang === languagePartials) {
        matched = lang;
      }
    });
  }
  return matched;
}

export const selectedLocale =
  checkDefaultLanguage() || process.env.VUE_APP_I18N_LOCALE || "en";

const i18n = createI18n({
  legacy: false,
  locale: selectedLocale,
  messages: messages,
});

export default i18n;
