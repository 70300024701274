
import { defineComponent, ref, Ref, onMounted, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

import VSection from "@/components/atoms/VSection/index.vue";

import SurveyJSContainer from "@/components/organisms/SurveyJSContainer/index.vue";
import VLoadSpinner from "@/components/organisms/VLoadSpinner/index.vue";
import VButton from "@/components/atoms/VButton/index.vue";

import { useStore } from "@/store";
import axios from "axios";
import { aimService } from "@/services/AIMService";

import { IndicatorOutput, Questionnaire } from "@/types/objects";

export default defineComponent({
  name: "ReRunQuestionnaireView",
  components: { SurveyJSContainer, VSection, VLoadSpinner, VButton },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const resultsUuid: string | string[] = route.params.id;
    const isLoading = ref(false);
    const error = ref();

    const isOwner = ref(false);
    const isExpert = ref(false);
    const isAdmin = ref(false);
    const allowedAccess = ref(false);

    const previousResults: Ref<IndicatorOutput | undefined> = ref();
    const answers: Ref<Record<string, unknown>> = ref({});
    const currentQuestionnaire: Ref<Questionnaire | undefined> = ref();
    const activeResults = computed(() => store.getters.activeResults);

    const confirmAccess = async () => {
      return isAdmin.value || isExpert.value || isOwner.value;
    };

    const getSurveyAndResults = async () => {
      isLoading.value = true;
      try {
        await store.dispatch("loadResultsFromApi", resultsUuid);
        previousResults.value = store.getters.activeResults;
        await store.dispatch("loadResultAnswers", resultsUuid);
        answers.value = store.getters.activeResultAnswers;
        await store.dispatch("loadQuestionnaireFromResult", resultsUuid);
        currentQuestionnaire.value = store.getters.activeQuestionnaire;
      } catch (e) {
        error.value = e;
        isLoading.value = false;
      }
      if (answers.value && currentQuestionnaire.value) {
        isLoading.value = false;
      } else {
        error.value = "Error loading the questionnaire answers";
        isLoading.value = false;
      }
    };

    const onComplete = async (results: JSON) => {
      const user = await aimService.getUser();
      store.dispatch("saveResultsToApi", {
        results: results,
        resultGroupUuid: previousResults.value?.resultGroupUuid,
        username: user?.profile.username,
        email: user?.profile.email,
      });
    };

    watch(activeResults, (curr: IndicatorOutput, prev: IndicatorOutput) => {
      if (curr.uuid != prev.uuid) {
        router.push(`/results/${curr.uuid}`);
      }
    });

    onMounted(async () => {
      store.dispatch("removeResultAnswer");
      store.dispatch("removeActiveQuestionnaire");

      getSurveyAndResults();

      //Getting permission to do re-run
      try {
        const resultsUsernameResponse = await axios.get(
          `${process.env.VUE_APP_API_ADDR}/results/getUsername/${resultsUuid}`
        );
        isOwner.value = resultsUsernameResponse.data;
      } catch (e) {
        console.dir(e);
      }
      isExpert.value = await aimService.isExpert();
      isAdmin.value = await aimService.isAdmin();
      allowedAccess.value = await confirmAccess();
    });

    return {
      t,
      isLoading,
      loadingText: computed(() => t("loadingResults")),
      error,
      getSurveyAndResults,
      currentQuestionnaire,
      answers,
      onComplete,
      allowedAccess,
    };
  },
});
