import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "title",
  class: "font-bold text-lg"
}
const _hoisted_2 = { class: "flex flex-row" }
const _hoisted_3 = {
  key: 0,
  id: "isAdmin"
}
const _hoisted_4 = {
  key: 1,
  id: "titleSpacer",
  class: "pl-2 pr-2"
}
const _hoisted_5 = {
  key: 2,
  id: "isExpert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(_ctx.cssClass)
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.user.email), 1),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.user.isAdmin)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.t("admin")), 1))
        : _createCommentVNode("", true),
      (_ctx.user.isAdmin && _ctx.user.isExpert)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, " | "))
        : _createCommentVNode("", true),
      (_ctx.user.isExpert)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.t("expert")), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}