
import {
  defineComponent,
  ref,
  Ref,
  onMounted,
  onBeforeUnmount,
  computed,
} from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { aimService } from "@/services/AIMService";
import { useToast } from "vue-toastification";

import VLoadSpinner from "@/components/organisms/VLoadSpinner/index.vue";
import VSection from "@/components/atoms/VSection/index.vue";
import VHeading from "@/components/atoms/VHeading/index.vue";
import VButton from "@/components/atoms/VButton/index.vue";
import VUserButton from "@/components/molecules/VUserButton/index.vue";
import VInput from "@/components/atoms/VInput/index.vue";
import VSwitch from "@/components/atoms/VSwitch/index.vue";
import VModal from "@/components/molecules/VModal/index.vue";

import { User } from "@/types/objects";

export default defineComponent({
  name: "AdminUserManagement",
  components: {
    VSection,
    VHeading,
    VLoadSpinner,
    VButton,
    VUserButton,
    VInput,
    VSwitch,
    VModal,
  },
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const store = useStore();

    const isLoading = ref(false);
    const error = ref();

    const newUserModalOpen = ref(false);
    const updateUserModalOpen = ref(false);

    const newUserToast = useToast();
    const updateUserToast = useToast();
    const deleteUserToast = useToast();

    const users = ref<User[]>();
    const searchStr = ref("");
    const isAdminFilter = ref(false);

    const newUser: Ref<User> = ref({
      username: "",
      email: "",
      isAdmin: false,
      isExpert: false,
    });
    const newUserEmailConfirm = ref("");

    const currUser: Ref<User> = ref({
      username: "",
      email: "",
      isAdmin: false,
      isExpert: false,
    });

    const isLoggedIn = async () => {
      return await aimService.isLoggedIn();
    };

    const getAllUsers = async () => {
      isLoading.value = true;
      if (await isLoggedIn()) {
        try {
          await store.dispatch("loadAllUsers");
          users.value = store.state.users;
          isLoading.value = false;
        } catch (e) {
          error.value = e;
          isLoading.value = false;
        }
      }
    };

    const setNewUserModalOpen = (isOpen: boolean) => {
      newUser.value.email = "";
      newUserEmailConfirm.value = "";
      newUserModalOpen.value = isOpen;
    };

    const setUpdateUserModalOpen = (isOpen: boolean, user?: User) => {
      if (user) {
        currUser.value.email = user.email;
        currUser.value.isAdmin = user.isAdmin;
        currUser.value.isExpert = user.isExpert;
        currUser.value.username = user.username;
      }
      updateUserModalOpen.value = isOpen;
    };

    const handleNewUserCreation = async () => {
      if (emailFieldsMatch.value) {
        const response = await store.dispatch("createNewUser", newUser.value);
        if (response?.error == "UsernameExistsException") {
          newUserToast.error(t("usernameExistsException"));
        } else {
          newUserToast.success(t("userCreatedSuccessfully"));
        }
        newUserModalOpen.value = false;
        store.dispatch("removeUsers");
        getAllUsers();
      } else {
        newUserToast.error(t("emailNotMatchingWarning"));
      }
    };

    const handleUpdateUser = async () => {
      const updateResponse = await store.dispatch("updateUser", currUser.value);
      if (updateResponse?.error) {
        updateUserToast.error(updateResponse?.error);
      } else {
        updateUserToast.success(t("userUpdatedSuccessfully"));
      }
      updateUserModalOpen.value = false;
      store.dispatch("removeUsers");
      getAllUsers();
    };

    const handleDeleteUser = async () => {
      const deleteResponse = await store.dispatch(
        "deleteUser",
        currUser.value.username
      );
      if (deleteResponse?.error) {
        deleteUserToast.error(deleteResponse?.error);
      } else {
        deleteUserToast.success(t("userDeletedSuccessfully"));
      }
      updateUserModalOpen.value = false;
      store.dispatch("removeUsers");
      getAllUsers();
    };

    const filteredUsers = computed((): User[] => {
      if (users.value) {
        return users.value?.filter((user: User) => {
          return (
            user.email.toLowerCase().includes(searchStr.value.toLowerCase()) &&
            (isAdminFilter.value ? user.isAdmin : true)
          );
        });
      } else {
        return [];
      }
    });

    const emailFieldsMatch = computed(() => {
      return (
        newUser.value.email != "" &&
        newUserEmailConfirm.value != "" &&
        newUser.value.email == newUserEmailConfirm.value
      );
    });

    onMounted(() => {
      store.dispatch("removeUsers");
      getAllUsers();
    });

    onBeforeUnmount(() => {
      store.dispatch("removeUsers");
    });

    return {
      t,
      isLoading,
      error,
      loadingText: computed(() => t("loadingUsers")),
      getAllUsers,
      filteredUsers,
      searchStr,
      isAdminFilter,
      newUserModalOpen,
      setNewUserModalOpen,
      newUser,
      newUserEmailConfirm,
      emailFieldsMatch,
      handleNewUserCreation,
      handleUpdateUser,
      updateUserModalOpen,
      setUpdateUserModalOpen,
      currUser,
      handleDeleteUser,
    };
  },
});
